jQuery(function($) {
    $(".dipi-lottie-icon").each(function() {

        let $this = $(this);
        let $data = $this.data("options");
        lottie.searchAnimations();

        let $lottie = lottie.loadAnimation({
            container: this,
            renderer: 'svg',
            loop: $data.loop,
            autoplay: $data.autoplay,
            path: $data.path,
            rendererSettings: {
                progressiveLoad: false
            }
        });

        if (!$data.autoplay) {
            $lottie.addEventListener('DOMLoaded', function() {
                $lottie.goToAndStop(parseInt($data.start_frame), true)
            })
        }

        if ($data.autoplay && $data.stop_on_hover === 'on') {
            $(this).parent().mouseenter(function() {
                $lottie.pause();
            });

            $(this).parent().mouseleave(function() {
                $lottie.play();
            });
        } else if (!$data.autoplay && $data.play_on_hover === 'on') {
            $(this).parent().mouseenter(function() {
                $lottie.play();
            });

            $(this).parent().mouseleave(function() {
                $lottie.pause();
            });
        }

        $lottie.setSpeed($data.speed);
        $lottie.setDirection($data.direction);


    });
});